
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'higuen';
  src: url('./assets/Higuen\ Serif.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.site-scroll {
  transition: ease-in-out 0.2s;
}